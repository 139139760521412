import {
    Container,
    Stack,
    Flex,
    Box,
    Heading,
    Text,
    Button,
    Image,
    Icon,
    VStack,
    Link,
    IconProps,
    Input,
    useColorModeValue,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Spacer,
    HStack,
    Divider,
    useToast,
  } from '@chakra-ui/react';
import {ColorModeSwitcher} from '../ColorModeSwitcher';
import { Link as ScrollLink } from 'react-scroll';
import ImgGesits1 from '../assets/gesits1.jpg';
import PlayIcon from './icons/PlayIcon';
import axios from 'axios';
import {useState} from 'react'

export type Members = Array<{
  name: string
  number: string
  location: string
  name_censored: string
}>


export default function Hero() {
  const toast = useToast()
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<Members>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  
  const [queryParam, setQueryParam] = useState("0000")
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setQueryParam(e.target.value)


  const handleButtonClick = () => {
    setLoading(true);
    if (queryParam.length == 4) {
      axios.get('/exec', {
        params: {
          number: queryParam
        }
      }).then((response) => {
        setLoading(false);
        if(response.data.data.length == 0) {
          toast({
            title: 'Info',
            description: "Nomor anggota tidak ditemukan",
            status: 'info',
            duration: 5000,
            isClosable: true,
          })
        }
        setResult(response.data.data);
        //console.log(response.data.data[0].name_censored);
      }).catch((error) => {
        setLoading(false);
        //console.error(error);
        toast({
          title: 'Gagal',
          description: "Terjadi kesalahan",
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      })
    }else {
      setLoading(false);
      toast({
        title: 'Gagal',
        description: "Format salah! Nomor anggota terdiri atas 4 digit angka",
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  return (
    <Container 
      minH="100vh" 
      maxW={'7xl'} 
      id="hero">
      <VStack maxW="100vw">
        <Flex w="100%" pt='4' pr='4'>
          <Spacer />
          <ColorModeSwitcher justifySelf="end"/>
        </Flex>
        <Stack
          align={'center'}
          spacing={{base: 8, md: 10}}
          py={{base: 16, md: 28}}
          direction={{base: 'column', md: 'row'}}>
          <Stack flex={1} spacing={{base: 5, md: 10}}>
            <Heading
              lineHeight={1.1}
              fontWeight={600}
              fontSize={{base: '3xl', sm: '4xl', lg: '6xl'}}>
              <Text
                as={'span'}
              //   _after={{
              //     content: "''",
              //     width: 'full',
              //     height: '50%',
              //     position: 'absolute',
              //     top: 1,
              //     left: 0,
              //     bg: 'red.600',
              //     zIndex: -1,
              //   }}
                position={'relative'}
                >
                GRID
              </Text>
              <br/>
              <Text as={'span'} color={'red.600'}>
                Gesits Riders Indonesia
              </Text>
            </Heading>
            <Text color={'gray.500'}>
              Komunitas pengendara motor listrik Gesits
            </Text>
            <Stack
              spacing={{base: 4, sm: 6}}
              direction={{base: 'column', sm: 'row'}}>
              <Button
                  as={Link}
                  href={"https://forum.gesitsrider.id/"}
                  rounded={'full'}
                  size={'lg'}
                  fontWeight={'normal'}
                  px={6}
                  colorScheme={'red'}
                  bg={'red.600'}
                  _hover={{bg: 'red.700'}}>
                Gabung Forum
              </Button>
              <Button
                onClick={onOpen}
                rounded={'full'}
                size={'lg'}
                fontWeight={'normal'}
                px={6}
                leftIcon={<PlayIcon h={4} w={4} color={'gray.300'}/>}>
                Cek Nomor Anggota
              </Button>
            </Stack>
          </Stack>
          <Flex
            flex={1}
            justify={'center'}
            align={'center'}
            position={'relative'}
            w={'full'}>
            <Blob
              w={['90vw','50vw']}
              h={['50vh','50vh']}
              position={'absolute'}
              top={['0','-16']}
              left={['0','-12vw']}
              zIndex={-1}
              color={useColorModeValue('red.50', 'red.500')}
            />
            <Box
              position={'relative'}
              height={{base:'300px',sm:'100px',lg:'300px'}}
              rounded={'2xl'}
              boxShadow={'2xl'}
              width={{base:'full',sm:'50%',lg:'full'}}
              overflow={'hidden'}>
              <Image
                alt={'Hero Image'}
                fit={'cover'}
                align={'center'}
                w={{base:'full',sm:'50%',lg:'full'}}
                h={'100%'}
                src={
                  ImgGesits1
                }
              />
            </Box>
          </Flex>
        </Stack>
      </VStack>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}  size={['xs','lg']}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Cek nomor anggota</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Container>
                <VStack>
                  <HStack my={8}>
                    <Input
                      maxLength={4}
                      value={queryParam}
                      onChange={handleChange}
                      type={'number'}
                      placeholder='Nomor'/>
                    <Button 
                    colorScheme='blue'
                    mr={3}
                    isLoading={loading}
                    onClick={handleButtonClick}>
                      Cari
                    </Button>
                  </HStack>
                  {result?.map((member) => {
                    return (
                      <Box p={4} w={['xs','s']} rounded={'md'} bg={'gray.900'}>
                        <HStack>
                          <Image
                          boxSize='100px'
                          background={"blue.800"}
                          fit={"contain"}
                          src={require('../assets/logo-grid.png')} alt={''} rounded={"full"}/>
                          
                          <VStack mx={8} alignItems={'start'}>
                            <Text textColor={"white"} fontSize={"xl"} fontWeight={'bold'}>Nama: {member.name_censored}</Text>
                            <Text textColor={"white"} fontSize={"md"}>Nomor Anggota: {member.number}</Text>
                            <Text textColor={"white"} fontSize={"md"}>Lokasi: {member.location}</Text>
                          </VStack>
                        </HStack>
                        <Divider my={2}/>
                      </Box>
                    )
                  })}
                </VStack>
              </Container>

            </ModalBody>

            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
    </Container>
  );
}

export const Blob = (props: IconProps) => {
  return (
    <Icon
      width={'100%'}
      viewBox="0 0 578 440"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
        fill="currentColor"
      />
    </Icon>
  );
};