import {
    Container,
    SimpleGrid,
    Image,
    Flex,
    Heading,
    Text,
    Stack,
    StackDivider,
    chakra,
    Divider,
    Link,
    Button,
    VStack,
    useColorModeValue,
  } from '@chakra-ui/react';
  import { ReactElement } from 'react';
  import PlayIcon from './icons/PlayIcon';
  import ImgGridConnect from '../assets/sc-gridconnect.jpeg';
  import ImgGesits from '../assets/sc-gesits.jpg';

  interface FeatureProps {
    text: string;
    iconBg: string;
    icon?: ReactElement;
  }

  export interface AppDetail {
    title : string;
    desc : string;
    type : string;
    pic : any;
    href : string;
  }

  const Feature = ({ text, icon, iconBg }: FeatureProps) => {
    return (
      <Stack direction={'row'} align={'center'}>
        <Flex
          w={8}
          h={8}
          align={'center'}
          justify={'center'}
          rounded={'full'}
          bg={iconBg}>
          {icon}
        </Flex>
        <Text fontWeight={600}>{text}</Text>
      </Stack>
    );
  };

  const Showcase = ({title, desc, type, pic, href } : AppDetail) => {
    return (
        <Container maxW={'5xl'} py={4}>
          <Stack spacing={6}>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
              <Stack spacing={4}>
                <Text
                  textTransform={'uppercase'}
                  color={'blue.400'}
                  fontWeight={600}
                  fontSize={'sm'}
                  bg={useColorModeValue('blue.50', 'blue.900')}
                  p={2}
                  alignSelf={'flex-start'}
                  rounded={'md'}>
                  {type}
                </Text>
                <Heading>{title}</Heading>
                <Text color={'gray.500'} fontSize={'lg'}>
                  {desc}
                </Text>
                <Stack
                  spacing={4}
                  divider={
                    <StackDivider
                      borderColor={useColorModeValue('gray.100', 'gray.700')}
                    />
                  }>
                  <Button
                    as={Link}
                    rounded={'full'}
                    size={'lg'}
                    fontWeight={'normal'}
                    px={6}
                    href={href}
                    isExternal
                    key={title+'_btn'}
                    leftIcon={<PlayIcon h={4} w={4} color={'gray.300'}/>}>
                    Download
                  </Button>
                </Stack>
              </Stack>
              <Flex>
                <Image
                  maxH={400}
                  rounded={'md'}
                  alt={title ?? '#'}
                  src={pic}
                  objectFit={'cover'}
                />
              </Flex>
            </SimpleGrid>
            <Divider mt={8} orientation='horizontal' />
          </Stack>
        </Container>
      );
  }
  
  export default function AppShowcase() {
    const APP_ITEMS : Array<AppDetail> = [
        {
            title : 'Grid Connect',
            desc : 'Aplikasi ini merupakan karya salah satu anggota GRID yang memiliki fitur lengkap',
            type : 'Android App',
            pic : ImgGridConnect,
            href : 'https://bit.ly/gesitssatukarya',
        },
        {
            title : 'Gesits Mods',
            desc : 'Karena aplikasi dari playstore tidak dapat digunakan, salah satu anggota' +
             'GRID memodifikasi aplikasi ini supaya bisa digunakan kembali',
            type : 'Android App',
            pic : ImgGesits,
            href : 'https://bit.ly/gesitsbadr',
        }
    ]
    return(
        <VStack>
          <chakra.h1
            textAlign={'center'}
            fontSize={'4xl'}
            py={10}
            mx={8}
            fontWeight={'bold'}>
            Aplikasi Pendukung
          </chakra.h1>
          {APP_ITEMS.map((child) => {
              return (
                  <Showcase 
                      {...child}
                  />
              )
          })}
        </VStack>
    )
  }