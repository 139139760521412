import {
    Box,
    chakra,
    Container,
    Link,
    Stack,
    Text,
    useColorModeValue,
    VisuallyHidden,
  } from '@chakra-ui/react';
  import { FaFacebookSquare } from 'react-icons/fa';
  import { ReactNode } from 'react';
  import { Logo } from '../Logo';
  
  import { Link as ScrollLink } from "react-scroll";
  
  const SocialButton = ({
                          children,
                          label,
                          href,
                        }: {
    children: ReactNode;
    label: string;
    href: string;
  }) => {
    return (
      <chakra.button
        bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
        rounded={'full'}
        w={8}
        h={8}
        cursor={'pointer'}
        as={'a'}
        href={href}
        display={'inline-flex'}
        alignItems={'center'}
        justifyContent={'center'}
        transition={'background 0.3s ease'}
        _hover={{
          bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
        }}>
        <VisuallyHidden>{label}</VisuallyHidden>
        {children}
      </chakra.button>
    );
  };
  
  export default function Footer() {
    return (
      <Box
        w={'100%'}
        bg={useColorModeValue('gray.50', 'gray.900')}
        color={useColorModeValue('gray.700', 'gray.200')}>
        <Container
          as={Stack}
          maxW={'6xl'}
          py={4}
          spacing={4}
          justify={'center'}
          align={'center'}>
          <Logo maxW={{base:'150px', md:'190px'}} pointerEvents="none"  />
          <Stack direction={'row'} spacing={6}>
            <Link 
                as={ScrollLink} 
                to={'hero'}
                spy={true} 
                smooth={true}
                duration={650}
                offset={-70}
                >Home</Link>
          </Stack>
        </Container>
  
        <Box
          borderTopWidth={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.700')}>
          <Container
            as={Stack}
            maxW={'6xl'}
            py={4}
            direction={{ base: 'column', md: 'row' }}
            spacing={4}
            justify={{ base: 'center', md: 'space-between' }}
            align={{ base: 'center', md: 'center' }}>
            <Text>© 2024 Gesits Riders Indonesia. All rights reserved</Text>
            <Stack direction={'row'} spacing={6}>
              <SocialButton label={'Facebook'} href={'https://www.facebook.com/groups/256895272043662/'}>
                <FaFacebookSquare />
              </SocialButton>
            </Stack>
          </Container>
        </Box>
      </Box>
    );
  }
