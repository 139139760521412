import React from 'react';
import {
  VStack,
  Flex,
  Box,
  Text,
  Button,
  useColorModeValue,
  IconButton,
  Divider,
  Link as ChakraLink,
  Spacer
} from '@chakra-ui/react';
import {Link} from 'react-router-dom'
import { ChevronLeftIcon } from "@chakra-ui/icons";

function Navbar() {
  return (
    <Flex
      position="sticky"
      top="0"
      bg={useColorModeValue('white', 'gray.800')}
      p="4"
      zIndex={'10'}
      justify="space-between"
      align="center"
      boxShadow="md"
      w={"full"}
    >
      <IconButton
        as={Link}
        to={"/"}
        aria-label="Back button"
        icon={<ChevronLeftIcon />}
        variant="ghost"
        size="md"
      />
      <Spacer/>
      <Text fontSize={"lg"} fontWeight={"semibold"}>List Dealer Gesits</Text>
      <Spacer/>
    </Flex>
  );
}

function PlaceList({ places }) {
  return (
    <Box pt={12} minW={"80vw"}>
      {places.map((place) => (
        <Box
          key={place.id}
          bg={'gray.700'}
          borderRadius="md"
          boxShadow="md"
          p="4"
          mb="4"
        >
          <Text fontWeight="bold" fontSize="xl">
            {place.title}
          </Text>
          <Text>{place.description}</Text>
          <iframe
            title={place.title}
            src={place.mapUrl}
            width="100%"
            height="300"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          ></iframe>
          <Box mt="4">
            <Button 
            as={ChakraLink}
            isExternal
            href={place.website}
            colorScheme="blue" mr="2">
              Website
            </Button>
          </Box>
        </Box>
      ))}
    </Box>
  );
}

const places = [
    {
      id: 1,
      title: "Gesits Cawang",
      description:
        "Service, Penjualan, Sparepart",
        mapUrl: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3966.1587813408455!2d106.87268867160888!3d-6.242794744628737!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f3bf012a7213%3A0x97989ab2ef3d3b9a!2sGesits%20G1!5e0!3m2!1sen!2sid!4v1689048665150!5m2!1sen!2sid",
        website: "https://gesitsmotors.com/"
    },
    {
      id: 2,
      title: "Gesits Bintaro",
      description:
      "Service, Penjualan, Sparepart",
        mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.956661671701!2d106.75063217453177!3d-6.269430161373905!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f18aebe4f4c7%3A0x6103f8f34e92f87e!2sGESITS%20BINTARO!5e0!3m2!1sen!2sid!4v1689054050137!5m2!1sen!2sid",
        website: "http://www.gesitsbintaro.id/"
    },
    {
      id: 3,
      title: "DEALER MOTOR LISTRIK GESITS LAMPUNG (dealer resmi)",
      description:
        "Service, Penjualan, Sparepart",
        mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3972.238872312869!2d105.28401977452037!3d-5.380507953806404!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e40db9a8b6eec67%3A0xf0bd440160cf74e1!2sDEALER%20MOTOR%20LISTRIK%20GESITS%20LAMPUNG%20(dealer%20resmi)!5e0!3m2!1sen!2sid!4v1689054243280!5m2!1sen!2sid",
        website: "https://www.tokopedia.com/ramikragomwk/gesits-g1-on-the-road-motor-listrik-sesuai-kondisi-di-lampung-hitam"
    },
  ];
  

export default function Dealers(){
  
  return (
    <VStack w={"full"}>
        <Navbar />
        <PlaceList places={places}/>
    </VStack>
  )
}