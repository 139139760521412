import axios from "axios";

axios.interceptors.request.use(
    (config) => {
        config.baseURL = "https://script.google.com/macros/s/AKfycbz5kBtZiWBpFbcUUi9kx9DZ80X8Q61yz1z3g76Yhqvtlw1OS8wWA8WMXzVtxgfONZ5aPw"
        config.headers ={
            'Content-Type': 'text/plain',
            'Referer': 'https://gesitsrider.id',
            'Accept' : '*/*',
        }
        return config;
    }
)