import * as React from 'react';
import { useRoutes } from 'react-router-dom';
import Landing  from '../pages/landing';
import Dealers from '../pages/dealers';
import { NotFound } from '../components';
import Calculator from '../pages/jarak';

export default function AppRoutes() {
    return useRoutes(
        [
            {
                path: '/',
                element: Landing(),
            },
            {
                path: '/dealer',
                element: Dealers(),
            },
            {
                path: '/calculator',
                element: Calculator(),
            },
            {
              path: '*',
              element: NotFound(),
            },
            
        ]
    )
}