import {
    Box,
    chakra,
    Flex,
    SimpleGrid,
    Image,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
  } from '@chakra-ui/react';
import React, {useState, useEffect} from 'react';
import PhotoAlbum from "react-photo-album";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ImageSize from '../utils/ImageSize';

interface GalleryCardProps {
  src: any;
  width: number;
  height: number;
}

const photos = [
  {
      src: require('../assets/gallery/gal1.jpg')
  },
  {
      src: require('../assets/gallery/gal2.jpg')
  },
  {
      src: require('../assets/gallery/gal3.jpg')
  },
  {
      src: require('../assets/gallery/gal4.jpg')
  },
  {
      src: require('../assets/gallery/gal5.jpg')
  },
  {
      src: require('../assets/gallery/gal6.jpg')
  },
  {
      src: require('../assets/gallery/gal7.jpeg')
  },
  {
      src: require('../assets/gallery/gal8.jpeg')
  },
  {
      src: require('../assets/gallery/gal9.jpeg')
  },
];

let GALLERY_ITEM : Array<GalleryCardProps> = [
  {
      src: require('../assets/gallery/gal1.jpg'),
      width: 600,
      height: 600
  },
  {
      src: require('../assets/gallery/gal2.jpg'),
      width: 600,
      height: 600
  },
  {
      src: require('../assets/gallery/gal3.jpg'),
      width: 600,
      height: 600
  },
  {
      src: require('../assets/gallery/gal4.jpg'),
      width: 600,
      height: 600
  },
  {
      src: require('../assets/gallery/gal5.jpg'),
      width: 600,
      height: 600
  },
  {
      src: require('../assets/gallery/gal6.jpg'),
      width: 600,
      height: 600
  },
  {
      src: require('../assets/gallery/gal7.jpeg'),
      width: 600,
      height: 600
  },
  {
      src: require('../assets/gallery/gal8.jpeg'),
      width: 600,
      height: 600
  },
  {
      src: require('../assets/gallery/gal9.jpeg'),
      width: 600,
      height: 600
  },
]

function DefaultGallery() {

  return (
    <SimpleGrid columns={{ base: 3, md: 4 }} spacing={{ base: 2, lg: 4 }}>
      {GALLERY_ITEM.map((child) => {
          return(
              <GalleryCard
                  {...child}
              />
          )
      })}
        
    </SimpleGrid>
  );
}

function GalleryCard(props: GalleryCardProps) {
  const { src } = props;
  return (
    <Box
      shadow={'xl'}
      rounded={'lg'}>
      <Flex justifyContent={'space-between'}>
          <Image
              as={LazyLoadImage}
              boxSize={{sm:'150px', md:'300px'}}
              borderRadius='lg'
              objectFit={{sm:'contain', md:'cover'}}
              src={src}
              alt='Gesits'
          />
      </Flex>
    </Box>
  );
}

async function GetPhotos() : Promise<GalleryCardProps[]>{
  const newPhotos = photos.map((photo, index) => {
    ImageSize(photo.src, index).then(({width , height, index}) => {
      const item : GalleryCardProps = {
        src:photo.src,
        width: width,
        height: height
      };
      let items : GalleryCardProps[] = GALLERY_ITEM;
      items[index] = item;
      GALLERY_ITEM = items;
    }).catch((e)=>{return null});
  })
  return [];
}

const pho = GetPhotos();

export default function Gallery() {
  const [gallery, setGallery] = useState<GalleryCardProps[]>([]);
  const [index, setIndex] = React.useState(-1);

  
  useEffect(() => {
    setGallery(GALLERY_ITEM);
  })

  return (
    <Box id={'gallery'} maxW="7xl" minH="50vh" mx={'auto'} pt={5} px={{ base: 2, sm: 12, md: 17 }}>
      <chakra.h1
        textAlign={'center'}
        fontSize={'4xl'}
        py={10}
        fontWeight={'bold'}>
        Gallery
      </chakra.h1>
      {/* <DefaultGallery /> */}
      <PhotoAlbum 
        layout="rows"
        photos={gallery}      
        onClick={({event, photo, index}) => setIndex(index)} />
      <Modal 
        isCentered
        isOpen={index >= 0} 
        onClose={() => setIndex(-1)}
        motionPreset='scale'
        size={'2xl'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> </ModalHeader>
          <ModalCloseButton onClick={() => setIndex(-1)} />
          <ModalBody>
            {((gallery.length > 0) && (gallery[index] != null)  )? (
              <Image
                as={LazyLoadImage}
                borderRadius='lg'
                objectFit={{sm:'contain', md:'cover'}}
                src={gallery[index].src}
                alt='Gesits'
              />
            ): (<Box/>)}
          </ModalBody>
          <ModalFooter>
            
          </ModalFooter>
        </ModalContent>
      </Modal>  
    </Box>
  );
}