import React, {useState} from 'react';
import {
  VStack,
  Flex,
  Box,
  Text,
  Container,
  Button,
  Input,
  IconButton,
  HStack,
  Select,
  Spacer,
  Center,
  Card,
  CardBody,
  Divider
} from '@chakra-ui/react';
import { NavBar } from '../../components/';
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';


const RangeCalculator = () => {

  const [voltage, setVoltage] = useState('');
  const [capacity, setCapacity] = useState('');
  const [whpkm, setWhpkm] = useState('28.8');
  const [whEnable, setWhEnable] = useState(false);
  const [range, setRange] = useState('');
  const [error, setError] = useState('');

  const calculateRange = () => {
    if (!voltage || !capacity) {
      setError('Harap isi voltase dan kapasitas.');
      return;
    }

    const voltageNumber = Number(voltage);
    const capacityNumber = Number(capacity);
    const whpkmNumber = Number(whpkm);

    if (isNaN(voltageNumber) || isNaN(capacityNumber) || isNaN(whpkmNumber)) {
      setError('Harap masukkan nomor yang valid untuk voltase, kapasitas, dan konsumsi daya');
      return;
    }

    if (voltageNumber <= 0 || capacityNumber <= 0 || whpkmNumber <= 0) {
      setError('Tidak boleh angka negatif');
      return;
    }

    const wattHoursPerKm = whpkm;
    const wattHours = voltageNumber * capacityNumber;
    const distanceTraveled = wattHours / wattHoursPerKm;
    setRange(distanceTraveled.toFixed(2));
    setError('');
  };

  return (
    <Card maxW={['90vw','75vw']} h={'full'}>
      <CardBody>
        <VStack py={8} w={'full'} px={['2vw','20vw']} spacing={4}>
          <Text fontWeight={"bold"}>Perkiraan Jarak</Text>
          <Text >Perhitungan ini berdasarkan dari spesifikasi Gesits G1 yang memiliki baterai 72v 20Ah untuk jarak tempuh 50km, 
            jika dihitung total watt-hour adalah 1440watt-hour. Jika kita bagi dengan jarak tempuh, 1440watt-hour/50km akan mendapatkan
            hasil 28.8 watt-hour per km.
          </Text>
          <Text>Hasil perhitungan ini hanya perkiraan kasar jarak tempuh. 
            Kondisi jalan, perilaku berkendara, pengaturan kontroller, dll akan mempengaruhi jarak tempuh sesungguhnya</Text>
          {/* <Select placeholder='Select option'>
            <option value='option1'>Option 1</option>
            <option value='option2'>Option 2</option>
            <option value='option3'>Option 3</option>
          </Select> */}
          <HStack wrap={'wrap'}>
            <Input
              maxW={"100px"}
              placeholder="Konsumsi daya"
              type="number"
              value={whpkm}
              disabled={!whEnable}
              onChange={(e) => setWhpkm(e.target.value)}
            />
            <Text minW={"100px"}>watt-hour/km</Text>
            <Button
            ml={2}
            onClick={() => {
              setWhEnable(!whEnable)
            }}>Ubah</Button>
          </HStack>
          <Divider/>
          <HStack>
            <Input
              placeholder="Voltase (V)"
              type="number"
              value={voltage}
              onChange={(e) => setVoltage(e.target.value)}
            />
            <Text>Volt</Text>
          </HStack>
          <HStack>
            <Input
              placeholder="Kapasitas (Ah)"
              type="number"
              value={capacity}
              onChange={(e) => setCapacity(e.target.value)}
            />
            <Text>Ah</Text>
          </HStack>
          <Button colorScheme="teal" onClick={calculateRange}>
            Hitung
          </Button>
          {error && <Text style={{ color: 'red' }}>{error}</Text>}
          {range && <Text>Perkiraan jarak tempuh adalah : {range} km.</Text>}
          {range && <Text mt={4}>({voltage}v x {capacity}Ah) / {whpkm}</Text>}
        </VStack>
      </CardBody>
    </Card>
  )
}

const PowerConsumption = () => {

  const [voltage, setVoltage] = useState('');
  const [capacity, setCapacity] = useState('');
  const [initialPercentage, setInitialPercentage] = useState('');
  const [endPercentage, setEndPercentage] = useState('');
  const [range, setRange] = useState('');
  const [whpkm, setWhpkm] = useState('');
  const [error, setError] = useState('');
  
  const calculateConsumtion = () => {
    if (!voltage || !capacity || !initialPercentage || !endPercentage || !range) {
      setError('Harap lengkapi semua parameter');
      return;
    }

    const voltageNumber = Number(voltage);
    const capacityNumber = Number(capacity);
    const initialPercentageNumber = Number(initialPercentage);
    const endPercentageNumber = Number(endPercentage);
    const rangeNumber = Number(range);

    if (isNaN(voltageNumber) || isNaN(capacityNumber) || isNaN(initialPercentageNumber) || isNaN(endPercentageNumber) || isNaN(rangeNumber)) {
      setError('Harap masukkan nomor yang valid untuk parameter');
      return;
    }

    if (voltageNumber <= 0 || capacityNumber <= 0 || initialPercentageNumber <= 0 || endPercentageNumber < 0 || rangeNumber <= 0) {
      setError('Tidak boleh angka negatif');
      return;
    }

    const energyUsed = (capacityNumber * (initialPercentageNumber / 100 - endPercentageNumber / 100));
    const wattHours = voltageNumber * energyUsed;
    const wattHoursPerKm = wattHours / rangeNumber;
    setWhpkm(wattHoursPerKm.toFixed(2));
    setError('');
  };

  return (
    <Card maxW={['90vw','75vw']} h={'full'}>
      <CardBody>
        <VStack py={8} w={'full'} px={['2vw','20vw']} spacing={4}>
          <Text fontWeight={"bold"}>Konsumsi daya</Text>
          <Text >Hitung konsumsi daya motor listrik berdasarkan persen baterai yang digunakan, voltase baterai, kapasitas baterai
          </Text>
          <HStack>
            <Input
              placeholder="Voltase (V)"
              type="number"
              value={voltage}
              onChange={(e) => setVoltage(e.target.value)}
            />
            <Text>Volt</Text>
          </HStack>
          <HStack>
            <Input
              placeholder="Kapasitas (Ah)"
              type="number"
              value={capacity}
              onChange={(e) => setCapacity(e.target.value)}
            />
            <Text>Ah</Text>
          </HStack>
          <HStack>
            <Input
              placeholder="Persentase awal"
              type="number"
              value={initialPercentage}
              onChange={(e) => setInitialPercentage(e.target.value)}
            />
            <Text>%</Text>
          </HStack>
          <HStack>
            <Input
              placeholder="Persentase akhir"
              type="number"
              value={endPercentage}
              onChange={(e) => setEndPercentage(e.target.value)}
            />
            <Text>%</Text>
          </HStack>
          <HStack>
            <Input
              placeholder="Jarak ditempuh"
              type="number"
              value={range}
              onChange={(e) => setRange(e.target.value)}
            />
            <Text>Km</Text>
          </HStack>
          <Button colorScheme="teal" onClick={calculateConsumtion}>
            Hitung
          </Button>
          {error && <Text style={{ color: 'red' }}>{error}</Text>}
          {whpkm && <Text>Konsumsi daya per kilometer = {whpkm} wh/km.</Text>}
        </VStack>
      </CardBody>
    </Card>
  )

}

const ChargeTime = () => {

  const [voltage, setVoltage] = useState('');
  const [capacity, setCapacity] = useState('');
  const [initialPercentage, setInitialPercentage] = useState('');
  const [endPercentage, setEndPercentage] = useState('');
  const [charger, setCharger] = useState('');
  const [price, setPrice] = useState('1444.7');
  const [totalPrice, setTotalPrice] = useState('');
  const [time, setTime] = useState('');
  const [error, setError] = useState('');
  
  const calculateConsumtion = () => {
    if (!voltage || !capacity || !initialPercentage || !endPercentage || !charger) {
      setError('Harap lengkapi semua parameter');
      return;
    }

    const voltageNumber = Number(voltage);
    const capacityNumber = Number(capacity);
    const initialPercentageNumber = Number(initialPercentage);
    const endPercentageNumber = Number(endPercentage);
    const chargerNumber = Number(charger);

    if (isNaN(voltageNumber) || isNaN(capacityNumber) || isNaN(initialPercentageNumber) || isNaN(endPercentageNumber) || isNaN(chargerNumber)) {
      setError('Harap masukkan nomor yang valid untuk parameter');
      return;
    }

    if (voltageNumber <= 0 || capacityNumber <= 0 || initialPercentageNumber < 0 || endPercentageNumber <= 0 || chargerNumber <= 0) {
      setError('Tidak boleh angka negatif');
      return;
    }

    const totalWatt = (voltageNumber * capacityNumber);
    const wattRequired = (totalWatt*(endPercentageNumber/100) - totalWatt*(initialPercentageNumber/100));
    console.log(`EKHIW watt ${wattRequired}`);
    setTotalPrice(calculateChargingCost(wattRequired,Number(price)))
    const hourRequired = wattRequired / chargerNumber;
    setTime(hourRequired);
    setError('');
  };

  return (
    <Card maxW={['90vw','75vw']} h={'full'}>
      <CardBody>
        <VStack py={8} w={'full'} px={['2vw','20vw']} spacing={4}>
          <Text fontWeight={"bold"}>Waktu Charger</Text>
          <Text >Hitung berapa waktu yang dibutuhkan untuk mengisi daya baterai </Text>
          <HStack>
            <Input
              placeholder="Voltase (V)"
              type="number"
              value={voltage}
              onChange={(e) => setVoltage(e.target.value)}
            />
            <Text>Volt</Text>
          </HStack>
          <HStack>
            <Input
              placeholder="Kapasitas (Ah)"
              type="number"
              value={capacity}
              onChange={(e) => setCapacity(e.target.value)}
            />
            <Text>Ah</Text>
          </HStack>
          <HStack>
            <Input
              placeholder="Persentase awal"
              type="number"
              value={initialPercentage}
              onChange={(e) => setInitialPercentage(e.target.value)}
            />
            <Text>%</Text>
          </HStack>
          <HStack>
            <Input
              placeholder="Persentase akhir"
              type="number"
              value={endPercentage}
              onChange={(e) => setEndPercentage(e.target.value)}
            />
            <Text>%</Text>
          </HStack>
          <HStack>
            <Input
              placeholder="Daya Charger (Watt)"
              type="number"
              value={charger}
              onChange={(e) => setCharger(e.target.value)}
            />
            <Text>watt</Text>
          </HStack>
          <HStack>
            <Input
              placeholder="Harga"
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
            <Text>Rp/Kwh</Text>
          </HStack>
          <Button colorScheme="teal" onClick={calculateConsumtion}>
            Hitung
          </Button>
          {error && <Text style={{ color: 'red' }}>{error}</Text>}
          {time && <Text>Waktu yang dibutuhkan = {formatChargingTime(time)}</Text>}
          {time && <Text>baterai akan mencapai {endPercentage}% pada {formatChargingFinishTime(time)}</Text>}
          {time && <Text>Tarif Rp{Math.floor(totalPrice)}</Text>}
        </VStack>
      </CardBody>
    </Card>
  )

}

function formatChargingTime(chargingTimeInHours) {
  const hours = Math.floor(chargingTimeInHours);
  const minutes = Math.floor((chargingTimeInHours - hours) * 60);
  const seconds = Math.round((chargingTimeInHours - hours - minutes / 60) * 3600);
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

function formatChargingFinishTime(chargingTimeInHours) {
  const now = dayjs();
  const chargingFinishTime = now.add(chargingTimeInHours, 'hour');
  const formattedChargingFinishTime = chargingFinishTime.format('HH:mm:ss DD-MM-YYYY');
  return formattedChargingFinishTime;
}

function calculateChargingCost(wattHour, pricePerKwh = 1444.7) {
  const kiloWattHour = wattHour / 1000;
  const chargingCost = kiloWattHour * pricePerKwh;
  return chargingCost;
}

function Navbar({navigate}) {
  
  return (
    <Box p={2} w={'full'} as="nav" role="navigation" bg="bg.accent.default">
      <HStack w={'full'} justifyContent={"space-between"}>
          <IconButton
            aria-label="Back button"
            icon={<ArrowBackIcon />}
            variant="ghost"
            size="lg"
            onClick={() => {
              navigate('/')
            }}
          />
          <Text>Kalkulator</Text>
          <Spacer/>
        </HStack>
    </Box>
  );
}


export default function Calculator(){
  const navigate = useNavigate();
  
  return (
    <VStack pb={'10vh'} textAlign={'center'}>
        <Navbar navigate={navigate} />        
        <Text fontWeight={"bold"}>Kumpulan kalkulator perkiraan jarak tempuh motor listrik</Text>
        <RangeCalculator/>
        <PowerConsumption/>
        <ChargeTime />
        
    </VStack>
  )
}