import {
    Container,
    SimpleGrid,
    Image,
    Flex,
    Heading,
    Text,
    Stack,
    StackDivider,
    chakra,
    Divider,
    Box,
    Link,
    Button,
    VStack,
    useColorModeValue,
  } from '@chakra-ui/react';
import { ReactElement } from 'react';
import ImgGridConnect from '../assets/sc-gridconnect.jpeg';
import ImgGesits from '../assets/sc-gesits.jpg';
import ReactPlayer from 'react-player';

  export interface ShowcaseDetail {
    title : string;
    desc : string;
    type : string;
    src : string;
    href : string;
  }

  const Showcase = ({title, desc, type, src, href } : ShowcaseDetail) => {
    return (
        <Container maxW={'5xl'} py={4}>
          <Stack spacing={6}>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
            <Stack spacing={4}>
              <Heading>{title}</Heading>
              <Text color={'gray.500'} fontSize={'lg'}>
                {desc}
              </Text>
              <Stack
                spacing={4}
                divider={
                  <StackDivider
                    borderColor={useColorModeValue('gray.100', 'gray.700')}
                  />
                }>
              </Stack>
            </Stack>
            <Flex>
              <ReactPlayer url={src} />
            </Flex>
          </SimpleGrid>
          <Divider mt={8} orientation='horizontal' />
          </Stack>
        </Container>
      );
  }
  
  export default function GesitsShowcase() {
    const APP_ITEMS : Array<ShowcaseDetail> = [
        {
            title : 'Takut banjir?',
            desc : 'Banyak sekali kekhawatiran akan kendaraan listrik terkena banjir, ' +
             'karna kita tau air dapat menyebabkan hubungan arus pendek(Short Circuit) ' +
             'pada alat elektronik. Yuk kita lihat video dari channel Novian Purnomo!',
            type : '',
            src : 'https://www.youtube.com/watch?v=r_LEiDN1jtU?t=262',
            href : '',
        },
        {
            title : 'Emangnya kuat buat nanjak?',
            desc : 'Katanya sih motor listrik tenaganya lemes, pasti gak kuat nanjak.' +
             'Eits!! kami sudah test melewati tanjakan krakalan, yuk kita lihat video dari channel krakalan official!',
            type : '',
            src : 'https://www.youtube.com/watch?v=gAmC_341Hmw?t=184',
            href : '',
        },
        {
            title : 'Pasti gak bisa jalan jauh!',
            desc : 'Sudah ada yang membuktikan solo touring menggunakan Gesits.' +
             'Ini dia Kang Maman. Yuk kita lihat videonya, jangan lupa di subscribe lho ya! hihihi',
            type : '',
            src : 'https://www.youtube.com/watch?v=fhBp78ur-pA',
            href : '',
        }
    ]
    return(
        <VStack>
          <chakra.h1
            textAlign={'center'}
            fontSize={'4xl'}
            py={10}
            mx={8}
            fontWeight={'bold'}>
            Masih ragu sama Gesits?
          </chakra.h1>
          {APP_ITEMS.map((child) => {
              return (
                  <Showcase 
                      {...child}
                  />
              )
          })}
        </VStack>
    )
  }