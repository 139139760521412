import React , {useEffect} from 'react';
import {
  VStack,
} from '@chakra-ui/react';
import { NavBar, Hero, Stats, Testimoni, Footer, AppShowcase, Gallery, GesitsShowcase } from '../../components/';
import OrganizationSection from './Organization';

export default function Landing(){
  
  return (
    <VStack>
        <NavBar />
        <Hero />
        <Stats />
        <AppShowcase />
        <GesitsShowcase />
        <Gallery />
        <Testimoni />
        <Footer />
    </VStack>
  )
}